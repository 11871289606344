import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import marijuana3 from './../../images/solution-ma1.jpg'
import marijuana4 from './../../images/solution-ma2.png'
import marijuana5 from './../../images/solution-ma3.jpg'
import marijuana6 from './../../images/solution-ma4.jpg'

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={3}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='工麻云-工业大麻溯源平台' />
        <div className='core-tech-wrap solution-wrap-marijuana'>
          <div className='main-img'></div>

          <div className='main-con' style={{ paddingBottom: '0px' }}>
            <h2>平台简介</h2>
            <p className='description-text'>
              易见纹语科技与云南省工业大麻行业协会共同打造和推广工业大麻区块链溯源及数字化监管平台&Prime;工麻云&Prime;。在平台上构建从种子出
              害到种植、加工、运输、数字仓储、可信销售等环节的监管及追溯体系;构建联盟链用以链接工业大麻的上下游企业、行业管理部门、X
              府监督部门、质检机构等;为工业大麻上下游企业提供溯源、质检、可信仓储等平台服务。通过全程的监控管理,共推工业大麻溯源监管
              ,通过数字化技术创新，推动工业大麻产业的健康持续发展。
            </p>

            <div className='trace-source' style={{ marginTop: '5rem' }}>
              <img src={marijuana3} />
            </div>
          </div>
          <div className='main-con'>
            <h2>平台架构图</h2>

            <div className='trace-source'>
              <img src={marijuana4} />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={marijuana5} />
        </div>
        <div className='core-tech-wrap solution-wrap solution-module'>
          <div className='main-con'>
            <div className='trace-source'>
              <img src={marijuana6} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
